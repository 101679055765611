<template>
  <div>
    <v-card max-width="400" class="pa-3">
      <v-text-field
        label="signal"
        filled
        solo
        v-model="payload.signal"
      ></v-text-field>
      <v-jsoneditor v-model="payload.payload" :options="options" :plus="false" height="400px">
      </v-jsoneditor>
      <v-btn @click="emitSignal(payload)">send signal</v-btn>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VJsoneditor from "v-jsoneditor";

export default {
  components: {
    VJsoneditor,
  },
  mounted() {},
  data() {
    return {
      payload: {
        signal: null,
        payload: {
          "id": "1",
        },
      },
      options: {
        onChange: (e) => {
          console.log(e);
        },
        mode: "code",
      },
    };
  },
  methods: {
    ...mapActions("rasaChannels", {
      getChannels: "getChannels",
      emitSignal: "emitSignal",
    }),
  },
};
</script>

<style>
</style>